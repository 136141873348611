import Swiper from 'swiper';
import 'swiper/swiper.min.css';

import { Pagination } from 'swiper/modules';

var app = {
    slider: function () {
      new Swiper('#details .swiper', {
          modules: [Pagination],
          autoplay: {
            delay: 2500,
          },
          slidesPerView: 1,
          spaceBetween: 20,
          loop: true,
          allowTouchMove: false,
          pagination: {
              el: "#details .swiper-pagination",
              clickable: true,
              renderBullet: function (i, className) {
                  let options = ['Vauxhall Crossland X,', 'Ford Fiesta ST']
      
                  return `<li class="${className}">${options[i]}</li>`;
              },
          },
      });
    },

    handleForm(){
        const dateTime = document.querySelector('[name="date-time"]'),
            form = document.querySelector('form'),
            thankYou = document.querySelector('#thank-you');

        // input value changing event
        document.addEventListener('input', function (e) {
            const requiredFieldsPopulated = Array.from(document.querySelectorAll('[required]')).filter((el) => el.value == '').length == 0;

            // change color of date time  
            dateTime.classList.toggle('text-black', dateTime.value !== '')

            // disabled condition of dateTime input
            dateTime.disabled = document.querySelector('[name="name"]').value == '' || document.querySelector('[name="phone-number"]').value == '';

            // enable submit when all required fields are populated
            document.querySelector('[type="submit"]').disabled = !requiredFieldsPopulated;
        }, false);

        form.addEventListener('submit', (e) => {
            e.preventDefault();
            
            const formData = new FormData(form),
                data = Object.fromEntries(formData);

            let object = {
                "blocks": [
                    {
                        "type": "section",
                        "text": {
                            "type": "mrkdwn",
                            "text": `name: ${data.name} \n number: ${data["phone-number"]} \n date: ${data["date-time"]} \n info: ${data["more-information"]}`
                        }
                    }
                ]
            };
            
            fetch("https://hooks.slack.com/services/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", {
                "method": "POST",
                "body" : JSON.stringify(object)
            })
                .then(() => {
                    form.remove();
                    
                    thankYou.classList.remove('hidden');
                    thankYou.classList.add('grid');
                })
                .catch((error) => console.log(error));
        })

    },

    init: function () {
        this.slider();
        this.handleForm();
    }
};

window.addEventListener('load', function () {
    app.init();
})
